import React from "react";
import { useShoppingCart } from "../context/ShoppingCartContext";
import { CartItemRow } from "./CartItemRow";
import CloseIcon from "@mui/icons-material/Close";
import "./ShoppingCart.css";
import { pf, getPageLocaleUrl } from "../utilities/helpers";
import usePageLanguage from "../hooks/usePageLanguage";
import { useAppSelector } from "../../../store/hooks";

type ShoppingCartProps = {
  isOpen: boolean;
};

const languageTranslations = {
  et: {
    total: "KOKKU",
    cart: "OSTUKORV",
    confirm_order: "VORMISTA TELLIMUS",
    sum: "Summa",
    discount: "Kohandus",
    delivery: "Tarne",
  },
  en: {
    total: "TOTAL",
    cart: "CART",
    confirm_order: "PROCEED TO CHECKOUT",
    sum: "Sum",
    discount: "Adjustment",
    delivery: "Delivery",
  },
};

export default function ShoppingCart({ isOpen }: ShoppingCartProps) {
  const {
    closeCart,
    locationProducts,
    cartItems,
    increaseCartQuantity,
    orderDeliveryMethod,
    totalCartDiscount,
    totalCartSum,
  } = useShoppingCart();

  const cartSidepanel =
    React.useRef() as React.MutableRefObject<HTMLInputElement>;

  const locale = usePageLanguage() || "et";
  const pages = useAppSelector((state) => state.builder.pages.list);

  const checkoutPage = pages.find(
    (page) => page.page_type === "checkout" && page.language.locale === locale
  );

  const languages = useAppSelector(
    (state) => state.builder.languageSettings.activeLanguages
  );

  var checkoutHref = checkoutPage
    ? getPageLocaleUrl(checkoutPage, languages)
    : "/et/checkout";
  if (checkoutHref.substring(0, 1) !== "/") {
    checkoutHref = "/" + checkoutHref;
  }

  const deliveryPrice =
    orderDeliveryMethod && orderDeliveryMethod.price && cartItems.length > 0
      ? Number(orderDeliveryMethod?.price)
      : 0;

  const closeCartSidepanel = (e: any) => {
    if (cartSidepanel.current && !cartSidepanel.current.contains(e.target)) {
      closeCart();
    }
  };
  document.addEventListener("mousedown", closeCartSidepanel);

  const handleDatalayerCheckout = () => {
    const locationProductItems = cartItems.map((item) => {
      const locationProduct = locationProducts.find((i) => i.id === item.id);
      const locationProductName = {};
      let categories: { category_name: { [k: string]: string } }[] = [];

      if (locationProduct) {
        // Format locationProductName
        Object.entries(locationProduct.translations).map((translation) => {
          const value = translation[1] as { display_name: string };
          locationProductName[translation[0]] = {
            name: value.display_name,
          };
        });

        // Format categories
        locationProduct.productCategories.map((category) => {
          const formattedCategory = {
            category_name: Object.fromEntries(
              Object.entries(category.locale_translations).map(
                ([locale, translation]) => [
                  locale,
                  translation as unknown as string,
                ]
              )
            ),
          };

          // Add the formatted category to the categories array
          categories.push(formattedCategory);
        });
      }

      if (!locationProduct) {
        return null;
      }

      return {
        item_code: locationProduct?.id,
        item_name: locationProductName,
        categories: categories,
        price_regular: locationProduct.price_total,
        discount_amount: Number(locationProduct.price_with_discounts),
        quantity: 1,
      };
    });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "begin_checkout",
      ecommerce: {
        total_amount: pf(
          cartItems.reduce((total, cartItem) => {
            const item = locationProducts.find((i) => i.id === cartItem.id);
            return total + (item?.price_total || 0) * cartItem.quantity;
          }, 0) -
            totalCartDiscount +
            (orderDeliveryMethod ? deliveryPrice : 0)
        ),
        products_amount: pf(
          cartItems.reduce((total, cartItem) => {
            const item = locationProducts.find((i) => i.id === cartItem.id);
            return total + (item?.price_total || 0) * cartItem.quantity;
          }, 0) - totalCartDiscount
        ),
        shipping_amount: orderDeliveryMethod ? deliveryPrice : 0,
        items: [...locationProductItems],
      },
    });
  };

  return (
    <div ref={cartSidepanel} className="cart-sidePanel">
      <div className="sidePanels" style={{ backgroundColor: "#fff" }}>
        <div
          id="cartSidepanel"
          className={`sidePanel${isOpen ? " visible" : ""}`}
        >
          <div style={{ fontSize: "21px", fontWeight: "400" }}>
            <span
              className="panel-title inline-block"
              style={{
                fontWeight: "600",
                fontSize: "18px",
                fontFamily: "Roboto, sans-serif",
                lineHeight: "21.6px",
              }}
            >
              {languageTranslations[locale]?.cart}
            </span>
            <CloseIcon
              className="btn-pure clickable2 padding-5 pull-right"
              onClick={() => closeCart()}
            />
          </div>
          <div
            className=""
            style={{ height: "calc(100% - 59px)", overflowX: "auto" }}
          >
            <div
              className="table-container padding-top-20"
              style={{
                overflowX: "auto",
                maxHeight: "calc(100% - 200px)",
                minHeight: "150px !important",
              }}
            >
              <div className="table tablerow-no-border cartTable">
                <div>
                  {cartItems.map((item) => (
                    <CartItemRow key={item.id} {...item} />
                  ))}
                </div>
              </div>
            </div>
            {orderDeliveryMethod?.id || totalCartDiscount ? (
              <div className="padding-top-20">
                <span style={{ fontWeight: "400", fontSize: "14px" }}>
                  {locale
                    ? languageTranslations[locale]?.sum
                    : languageTranslations.et.sum}
                  :
                  <span className="pull-right">
                    <span className="cartTotal">
                      {pf(
                        cartItems.reduce((total, cartItem) => {
                          const item = locationProducts.find(
                            (i) => i.id === cartItem.id
                          );
                          return (
                            total + (item?.price_total || 0) * cartItem.quantity
                          );
                        }, 0)
                      )}
                    </span>{" "}
                    &euro;
                  </span>
                </span>
              </div>
            ) : (
              ""
            )}

            {totalCartDiscount && totalCartDiscount > 0 ? (
              <div className="">
                <span
                  className="discount-price-color"
                  style={{ fontWeight: "400", fontSize: "14px" }}
                >
                  {languageTranslations[locale]?.discount}:
                  <span className="pull-right">
                    <span className="cartTotal">
                      {pf(Number(-totalCartDiscount))}
                    </span>{" "}
                    &euro;
                  </span>
                </span>
              </div>
            ) : (
              ""
            )}
            {orderDeliveryMethod?.id && cartItems.length > 0 && (
              <div className="">
                <div style={{ fontWeight: "400", fontSize: "14px" }}>
                  {languageTranslations[locale]?.delivery}:
                  <span className="pull-right">
                    <span className="cartTotal">{pf(deliveryPrice)}</span>{" "}
                    &euro;
                  </span>
                </div>
              </div>
            )}
            <div className="">
              <div style={{ fontWeight: "400", fontSize: "18px" }}>
                {languageTranslations[locale]?.total}:
                <span className="pull-right">
                  <span className="cartTotal">
                    {pf(
                      cartItems.reduce((total, cartItem) => {
                        const item = locationProducts.find(
                          (i) => i.id === cartItem.id
                        );
                        return (
                          total + (item?.price_total || 0) * cartItem.quantity
                        );
                      }, 0) -
                        totalCartDiscount +
                        (orderDeliveryMethod ? deliveryPrice : 0)
                    )}

                    {/* {pf(
                      totalCartSum -
                        totalCartDiscount +
                        (orderDeliveryMethod ? deliveryPrice : 0)
                    )} */}
                  </span>{" "}
                  &euro;
                </span>
              </div>
            </div>
            <div className="padding-top-20">
              <a
                onClick={() => {
                  handleDatalayerCheckout();
                }}
                className="btn btn-primary shop-controlled-border-radius confirm-order-button"
                href={checkoutHref}
                style={{ width: "100%", color: "#fff" }}
              >
                {languageTranslations[locale]?.confirm_order}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
