import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage1Avaleht from "./pages/Webpage1Avaleht";
import Webpage2Tellimus from "./pages/Webpage2Tellimus";
import Webpage4Privaatsuspoliitika from "./pages/Webpage4Privaatsuspoliitika";
import Webpage5K__psised from "./pages/Webpage5K__psised";
import Webpage6Toode from "./pages/Webpage6Toode";

var baseURL = 'https://almaregrill.kassahaldus.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiZmE3MDMzNDhmNTM0YjAyNjVmNGI5ODhjNjE0NDVlMjkyNGMxM2QxZTFlMzgzMWM5NWQyYTFmMWQ3MmM0NTk3ZjU4ZDA5YjhiOWYzOTYxMDciLCJpYXQiOjE3NDI4MTQ0NzQuMzYxNTkxLCJuYmYiOjE3NDI4MTQ0NzQuMzYxNTk2LCJleHAiOjE3NDI5MDA4NzQuMzU2NTg5LCJzdWIiOiI2OCIsInNjb3BlcyI6W119.TCJ3nSwQ3160P09FmJOW3IAyyZM4ZCmYvj3UeUk4p4l2OSaHY8zt6f2XtXIQkh9SVbvhAqW2C9kd6pidI-7GnRA9kLX8lLVXvMSQrfIKnhlbcYgr9WxseP-xqnmrLgO9J2HGFfeqBvtjKuoQek-ssaA9f92BPVr7miYnquCOMiC2Yi6ILVew5ryjbEYIrXyEoRCbi-VjzDl4QGBpsfvKZV4NxJuDg_27cizlWbt7QW2eP6TQA01qWG31gfGOzcuubhOcbB5cnizkGHkoEtcUOZruPLW2N5bQXVoOyir5dg-qlJXcgO1CwLI6QqixEqkoYCkBNFQQb9ezilC3GARcxPswBdz7iSkUMpiC2jUHdLh43Ib7i4_uiExX9_2DzaLzwc5OCOm0IG5GeMRVFyIECfMAXXImrYC4thfIEgVRH5GJwIHbJJzxwL6jpxYdnyq4e4PlfyeLYZemNDIq427pvGZ2XxUi_a9z1sfXW5q_efjsAH_dyXk3z0pSFk-oalfw7FyW60Ldn5w0NuIMOdomtjegBTud0NCq3iAiAQLhYKQYewhVwj4TiuJqZSiBg1GQK3Hrqj6NgmzjKyXerLVdKZyz19INuxWVXsCZGj1kQTtnT2xXN3TXDUc1GPn628KDG-vOiFTrJtEUERaVHYKaCjBo36g3EpTGF7mYt5a4BqU',
    'Access-api-key': "eyJpdiI6IkJoMkdpcXNydDVhZ1dPOEtEUTVmWWc9PSIsInZhbHVlIjoiOUFyd3VOSkRRdWtQRldtK2Qzam9jZz09IiwibWFjIjoiYzRmNTkyNDI2MzZhZWYyZmNhY2Q4YzliZTE0MDA5M2MxNjM2NjlhMTM5OTUzNTk1ZGI2M2Y0OGUxMmU3OGQ4ZiIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6IlFodi8rclFUZXdaMHp5ejd6M1p4bkE9PSIsInZhbHVlIjoibVZ5bmNmQmVwTTVsbm5HNG52R3VaZz09IiwibWFjIjoiMjdiNTc0ZDBmNGVmODFhMzg2NTMxYTA0ODVjNWIyMzAwNmI4ODVhNjg0OGQ4YTdhY2NhY2YyMzc1MzkxYjZiYiIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "GTM-KZ74CBG6"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage1Avaleht />} exact />;
                                                                    <Route path="/et/tellimus" element={<Webpage2Tellimus />} exact />;
                                                                    <Route path="/et/privacyPolicy" element={<Webpage4Privaatsuspoliitika />} exact />;
                                                                    <Route path="/et/cookiePolicy" element={<Webpage5K__psised />} exact />;
                                                                    <Route path="/et/toode/:id" element={<Webpage6Toode />} exact />;
                                                                
                                                                <Route path="*" element={<Webpage1Avaleht />} exact />;
                                        </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}