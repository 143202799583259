import TextComponent from "../TextComponent";
import ProductsComponent from "../Products/ProductsComponent";
import ProductDetailComponent from "../ProductDetail/ProductDetailComponent";
import Checkout from "../Checkout/Checkout";
import ImageComponent from "../ImageComponent";
import ButtonComponent from "../ButtonComponent";
import NavItemComponent from "../NavItemComponent";
import SeparatorComponent from "../SeparatorComponent";
import SectionComponent from "../SectionComponent";
import FooterComponent from "../FooterComponent";
import HeaderComponent from "../HeaderComponent";
import InputComponent from "../InputComponent";
import FormComponent from "../FormComponent";
import ProductsServicesComponent from "../ProductsServices/ProductsServicesComponent";
import InnerSectionComponent from "../InnerSectionComponent";
import MediaSliderComponent from "../MediaSliderComponent";
import AccordionComponent from "../AccordionComponent";
import ContentComponent from "../ContentComponent";
import SingleProductComponent from "../SingleProductComponent";
import ProductSliderComponent from "../ProductSliderComponent";
import CartComponent from "../CartComponent";
import LanguageComponent from "../LanguageComponent";
import NavigationContainerComponent from "../NavigationContainerComponent";
import IframeComponent from "../IframeComponent";

export var defaultComponents: { [key: string]: any } = {
  text: TextComponent,
  ProductsComponent: ProductsComponent,
  ProductDetailComponent: ProductDetailComponent,
  Checkout: Checkout,
  Products: ProductsComponent,
  ProductsServicesComponent: ProductsServicesComponent,
  inner_section: InnerSectionComponent,
  image: ImageComponent,
  Button: ButtonComponent,
  SingleProductComponent: SingleProductComponent,
  cart: CartComponent,
  "product-slider": ProductSliderComponent,
  "language-select": LanguageComponent,
  MediaSlider: MediaSliderComponent,
  Accordion: AccordionComponent,
  "navigation-container": NavigationContainerComponent,
  iframe: IframeComponent,
  // Link: LinkComponent,
  section: SectionComponent,
  separator: SeparatorComponent,
  ContentComponent: ContentComponent,
  NavItemComponent: NavItemComponent,
  footer: FooterComponent,
  header: HeaderComponent,
  input: InputComponent,
  form: FormComponent,
  "nav-item": NavItemComponent,
};
